import React, { Fragment, useRef } from "react";
import ReactDOM from "react-dom";
import popover from "./css/popover.css";
// eslint-disable-next-line no-unused-vars
import orders from "./css/something-wrong-order.css";
import greenArrow from "./css/greenArrow.css";
import { usePopperTooltip } from "react-popper-tooltip";

const getPopoverStyle = name => {
  const customPopoverStyle = {
    ordersDetailButton: "orders.detail-button-container-popover",
    estimatedTimeButton: "popover.estimated-time-container-popover",
    detailButton: "popover.detail-button-container-popover",
    green: "greenArrow.popper",
    customerOrderContext: "popover.popper-customerOrderContext",
    translatorOrderContext: "popover.popper-translatorOrderContext",
    orderContext: "popover.popper-orderContext",
    addFiles: "popover.popper-addFiles",
    export: "popover.popper-export",
    default: "popover.popper",
    tableMenu: "popover.popper-table-menu",
    underHeaderTitle: "popover.underheader-title",
    languageBox: "popover.popper popover.popper-languageBox",
    underHeader: "popover.popper popover.popperUnderHeader",
  };

  return customPopoverStyle[name] ? customPopoverStyle[name] : customPopoverStyle.default;
};

const getPopoverQuestionHintStyle = name => {
  const customPopoverStyle = {
    green: "greenArrow.questionHint",
    default: "popover.questionHint",
  };

  return customPopoverStyle[name] ? customPopoverStyle[name] : customPopoverStyle.default;
};

const getPopoverArrowStyle = name => {
  const customPopoverArrowStyle = {
    greenArrow: greenArrow.popper__arrow,
    default: popover.popper__arrow,
    underHeaderTitleArrow: popover.underHeaderTitleArrow,
  };

  return customPopoverArrowStyle[name] ? customPopoverArrowStyle[name] : customPopoverArrowStyle.default;
};

export const PopoverTest = ({
  referenceElement,
  popperElement,
  placement,
  handleBlur,
  popperStyleName,
  popperArrowStyleName = "default",
  isShowPopover,
  mainText,
  wrapperStyle,
  isShowArrow = true,
}) => {
  const arrowElementRef = useRef(null);
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(
    {
      closeOnOutsideClick: true,
      trigger: "click",
      placement,
      visible: isShowPopover ?? undefined,
      onVisibleChange: isVisible => {
        if (!isVisible) {
          handleBlur && handleBlur();
        }
      },
    },
    {
      modifiers: [
        {
          name: "arrow",
          options: { element: arrowElementRef && arrowElementRef.current },
        },
      ],
    }
  );
  const container = document.getElementById("app-container");

  return (
    <Fragment>
      <div style={wrapperStyle} ref={setTriggerRef}>
        {referenceElement}
      </div>
      {(visible || isShowPopover) &&
        ReactDOM.createPortal(
          <div ref={setTooltipRef} styleName={getPopoverStyle(popperStyleName)} {...getTooltipProps()}>
            {isShowArrow && (
              <div
                ref={arrowElementRef}
                className={getPopoverArrowStyle(popperArrowStyleName)}
                {...getArrowProps()}
              ></div>
            )}
            {mainText && <div styleName={getPopoverQuestionHintStyle(popperStyleName)}>{mainText}</div>}
            {popperElement}
          </div>,
          container
        )}
    </Fragment>
  );
};
