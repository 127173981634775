export const cursorWait = request => {
  request ? document.body.classList.add("app-wait") : document.body.classList.remove("app-wait");
};

export const cursorLocalWait = request => {
  request ? document.body.classList.add("local-wait") : document.body.classList.remove("local-wait");
};

export const cursorNoneEvent = request => {
  request ? document.body.classList.add("local-none-event") : document.body.classList.remove("local-none-event");
};

export const cursorProgress = request => {
  request ? document.body.classList.add("cursor-progress") : document.body.classList.remove("cursor-progress");
};
