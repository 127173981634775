import { OrderStatus } from "@alconost/nitro-services-api/src/proto/base_pb";
const getArrayStatuses = status => {
  const statuses = {
    queue: ["queue", "revision", "ai_progress", "ai_failed", "ai_imposible_to_convert"],
    progress: ["progress", "editor_progress"],
    done: ["done"],
    archived: ["done"],
    edit: ["edit"],
    incomplete: ["progress", "edit", "revision_progress", "editor_progress"],
    revision_progress: ["revision_progress"],
    default: "",
  };

  return statuses[status] || statuses["default"];
};

export const getCorrectStatusesList = status => {
  const arrayStatus = status && getArrayStatuses(status);

  return (
    arrayStatus &&
    arrayStatus.map(status => {
      const statusUpperCase = status.toUpperCase();
      return OrderStatus[statusUpperCase];
    })
  );
};
