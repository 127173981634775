var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OrderServiceClient } from "@alconost/nitro-services-api/src/proto/order_service_grpc_web_pb";
import { limit } from "stringz";
import { getAuthMetadata, grpcRequest } from "../newGrpcUtils";
import { GetOrderRequest, OrderId, OrderIds, CommentRequest, GetNextOrderRequest, OrderTableSort, SortOrderField, GetNextDesktopOrderRequest, DetectLanguageRequest, ReadMessagesRequest, ProlongOrderRequest, CompleteOrderRequest, CompleteOrderFileRequest, UpdateArchiveRequest, OrderGroupUuid, FinishEditOrderRequest, FinishEditOrderFileRequest, RevisionOrderRequest, UpdatePauseRequest, SaveDraftRequest, UpdateAdvancedOptionsRequest, AdvancedOptions, OrderTranslationType, UnreadStatusRequest, ListActionRequiredOrdersTranslatorRequest, CreateOrderRequest, CreateOrderFileRequest, ListOrdersRequest, ListCompletedOrdersCustomerRequest, CreateProjectRequest, GetProjectRequest, InsertAiTextRequest, } from "@alconost/nitro-services-api/src/proto/order_service_pb";
import { Empty, Page, SortOrder } from "@alconost/nitro-services-api/src/proto/base_pb";
import { getCid } from "../../helpers/googleAnalyticsUtils";
import { getCorrectStatusesList } from "./helpers";
export var getOrderAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, needTags = _b.needTags, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetOrderRequest();
                request.setOrderId(orderId);
                request.setNeedTags(needTags);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "getOrderAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getOrderTranslatorRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, needTags = _b.needTags, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetOrderRequest();
                request.setOrderId(orderId);
                request.setNeedTags(needTags);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "getOrderTranslator",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getOrderCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, needTags = _b.needTags, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetOrderRequest();
                request.setOrderId(orderId);
                request.setNeedTags(needTags);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "getOrderCustomer",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var setOrderUnreadByAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "setOrderUnreadByAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var acceptOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "acceptOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "getOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var continueTranslatorOrderWithDraftRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "continueTranslatorOrderWithDraft",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var startAdminEditDraftOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "startAdminEditDraftOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var startTranslatorEditOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "startTranslatorEditOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var startEditorEditOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "startEditorEditOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var startAdminEditOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "startAdminEditOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var startRevisionOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "startRevisionOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var orderViewedRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "orderViewed",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var skipProgressOrdersTranslatorRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderIds();
                request.setOrderIdsList(orderIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "skipProgressOrdersTranslator",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var skipProgressOrdersAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderIds();
                request.setOrderIdsList(orderIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "skipProgressOrdersAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var cancelOrdersAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderIds();
                request.setOrderIdsList(orderIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "cancelOrdersAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var cancelOrdersCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderIds();
                request.setOrderIdsList(orderIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "cancelOrdersCustomer",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var ordersViewedRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderIds();
                request.setOrderIdsList(orderIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "ordersViewed",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var exportOrdersCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderIds();
                request.setOrderIdsList(orderIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "exportOrdersCustomer",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var exportOrdersAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderIds();
                request.setOrderIdsList(orderIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "exportOrdersAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getQueueStatusRequest = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var request, metadata;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                request = new Empty();
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _a.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "getQueueStatus",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getAdminQueueStatusRequest = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var request, metadata;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                request = new Empty();
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _a.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "getAdminQueueStatus",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var addCommentAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, message = _b.message, attachments = _b.attachments, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new CommentRequest();
                request.setOrderid(orderId);
                request.setMessage(message);
                request.setAttachmentsList(attachments);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "addCommentAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var addCommentTranslatorRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, message = _b.message, attachments = _b.attachments, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new CommentRequest();
                request.setOrderid(orderId);
                request.setMessage(message);
                request.setAttachmentsList(attachments);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "addCommentTranslator",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var addCommentCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, message = _b.message, attachments = _b.attachments, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new CommentRequest();
                request.setOrderid(orderId);
                request.setMessage(message);
                request.setAttachmentsList(attachments);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "addCommentCustomer",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getNextAdminOrderNewRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, orderSortList, metadata;
    var orderId = _b.orderId, next = _b.next, status = _b.status, sortList = _b.sortList, search = _b.search, locale = _b.locale, isUnread = _b.isUnread, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetNextOrderRequest();
                orderSortList = sortList.reduce(function (accumulator, element) {
                    var _a;
                    var orderTableSort = new OrderTableSort();
                    orderTableSort.setStatus(element.status);
                    //@ts-ignore
                    orderTableSort.setField(SortOrderField[element.field]);
                    //@ts-ignore
                    orderTableSort.setOrder(SortOrder[element.order]);
                    orderTableSort.setSourceLanguagesList(element.sourceLanguage);
                    orderTableSort.setTargetLanguagesList(element.targetLanguage);
                    orderTableSort.setFormat((_a = element.format) === null || _a === void 0 ? void 0 : _a.value);
                    orderTableSort.setCreatedBefore(element.createdBefore);
                    orderTableSort.setCreatedAfter(element.createdAfter);
                    orderTableSort.setCompletedBefore(element.completedBefore);
                    orderTableSort.setCompletedAfter(element.completedAfter);
                    orderTableSort.setWorkspaceName(element.workspaceName);
                    accumulator.push(orderTableSort);
                    return accumulator;
                }, []);
                request.setOrderId(orderId);
                request.setNext(next);
                request.setStatus(status);
                request.setSortsList(orderSortList);
                request.setSearch(search);
                request.setLocale(locale);
                request.setIsUnread(isUnread);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "getNextAdminOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getNextCustomerOrderNewRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, orderSortList, metadata;
    var orderId = _b.orderId, next = _b.next, status = _b.status, sortList = _b.sortList, search = _b.search, locale = _b.locale, isUnread = _b.isUnread, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetNextOrderRequest();
                orderSortList = sortList.reduce(function (accumulator, element) {
                    var _a;
                    var orderTableSort = new OrderTableSort();
                    orderTableSort.setStatus(element.status);
                    //@ts-ignore
                    orderTableSort.setField(SortOrderField[element.field]);
                    //@ts-ignore
                    orderTableSort.setOrder(SortOrder[element.order]);
                    orderTableSort.setSourceLanguagesList(element.sourceLanguage);
                    orderTableSort.setTargetLanguagesList(element.targetLanguage);
                    orderTableSort.setFormat((_a = element.format) === null || _a === void 0 ? void 0 : _a.value);
                    orderTableSort.setCreatedBefore(element.createdBefore);
                    orderTableSort.setCreatedAfter(element.createdAfter);
                    orderTableSort.setCompletedBefore(element.completedBefore);
                    orderTableSort.setCompletedAfter(element.completedAfter);
                    accumulator.push(orderTableSort);
                    return accumulator;
                }, []);
                request.setOrderId(orderId);
                request.setNext(next);
                request.setStatus(status);
                request.setSortsList(orderSortList);
                request.setSearch(search);
                request.setLocale(locale);
                request.setIsUnread(isUnread);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "getNextCustomerOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getNextTranslatorOrderNewRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, orderSortList, metadata;
    var orderId = _b.orderId, next = _b.next, status = _b.status, sortList = _b.sortList, search = _b.search, locale = _b.locale, isUnread = _b.isUnread, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetNextOrderRequest();
                orderSortList = sortList.reduce(function (accumulator, element) {
                    var _a;
                    var orderTableSort = new OrderTableSort();
                    orderTableSort.setStatus(element.status);
                    //@ts-ignore
                    orderTableSort.setField(SortOrderField[element.field]);
                    //@ts-ignore
                    orderTableSort.setOrder(SortOrder[element.order]);
                    orderTableSort.setSourceLanguagesList(element.sourceLanguage);
                    orderTableSort.setTargetLanguagesList(element.targetLanguage);
                    orderTableSort.setFormat((_a = element.format) === null || _a === void 0 ? void 0 : _a.value);
                    accumulator.push(orderTableSort);
                    return accumulator;
                }, []);
                request.setOrderId(orderId);
                request.setNext(next);
                request.setStatus(status);
                request.setSortsList(orderSortList);
                request.setSearch(search);
                request.setLocale(locale);
                request.setIsUnread(isUnread);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "getNextTranslatorOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getNextDesktopOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var skippedOrderIds = _b.skippedOrderIds, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetNextDesktopOrderRequest();
                request.setSkippedOrderIdsList(skippedOrderIds);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "getNextDesktopOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var detectLanguageRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, textPart, metadata;
    var text = _b.text, fileUuid = _b.fileUuid, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new DetectLanguageRequest();
                textPart = text && limit(text, 256);
                request.setText(textPart);
                request.setFileUuid(fileUuid);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "detectLanguage",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var readMessagesRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var messageIds = _b.messageIds, role = _b.role, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ReadMessagesRequest();
                request.setMessageIdsList(messageIds);
                request.setRole(role);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "readMessages",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var prolongOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var calculatedTime, request, metadata;
    var orderId = _b.orderId, time = _b.time, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                calculatedTime = time * 60 * 1000;
                request = new ProlongOrderRequest();
                request.setOrderid(orderId);
                request.setTime(calculatedTime);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "prolongOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var completeOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, targetText = _b.targetText, hint = _b.hint, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new CompleteOrderRequest();
                request.setOrderid(orderId);
                request.setTargettext(targetText);
                request.setHint(hint);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "completeOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var completeOrderFileRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, segments = _b.segments, hint = _b.hint, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new CompleteOrderFileRequest();
                request.setOrderid(orderId);
                request.setSegmentsList(segments);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "completeOrderFile",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateArchiveRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, archived = _b.archived, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdateArchiveRequest();
                request.setOrderIdsList(orderIds);
                request.setArchived(archived);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "updateArchive",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var acceptGroupRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var uuid = _b.uuid, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderGroupUuid();
                request.setUuid(uuid);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "acceptGroup",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var finishRevisionOrderFileRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, segments = _b.segments, hint = _b.hint, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new FinishEditOrderFileRequest();
                request.setOrderid(orderId);
                request.setSegmentsList(segments);
                request.setHint(hint);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "finishRevisionOrderFile",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var finishRevisionOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, targetText = _b.targetText, hint = _b.hint, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new FinishEditOrderRequest();
                request.setOrderid(orderId);
                request.setTargetText(targetText);
                request.setHint(hint);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "finishRevisionOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var revisionOrderAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, message = _b.message, attachments = _b.attachments, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new RevisionOrderRequest();
                request.setOrderid(orderId);
                request.setMessage(message);
                request.setAttachmentsList(attachments);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "revisionOrderAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var revisionOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, message = _b.message, attachments = _b.attachments, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new RevisionOrderRequest();
                request.setOrderid(orderId);
                request.setMessage(message);
                request.setAttachmentsList(attachments);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "revisionOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var finishEditorOrderFileRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, segments = _b.segments, hint = _b.hint, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new FinishEditOrderFileRequest();
                request.setOrderid(orderId);
                request.setSegmentsList(segments);
                request.setHint(hint);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "finishEditorOrderFile",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var finishEditorOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, targetText = _b.targetText, hint = _b.hint, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new FinishEditOrderRequest();
                request.setOrderid(orderId);
                request.setTargetText(targetText);
                request.setHint(hint);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "finishEditorOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var finishEditOrderFileRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, segments = _b.segments, hint = _b.hint, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new FinishEditOrderFileRequest();
                request.setOrderid(orderId);
                request.setSegmentsList(segments);
                request.setHint(hint);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "finishEditOrderFile",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var finishEditOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, targetText = _b.targetText, hint = _b.hint, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new FinishEditOrderRequest();
                request.setOrderid(orderId);
                request.setTargetText(targetText);
                request.setHint(hint);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "finishEditOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updatePauseAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderIds = _b.orderIds, paused = _b.paused, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdatePauseRequest();
                request.setOrderIdsList(orderIds);
                request.setPaused(paused);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "updatePauseAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var saveDraftRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, segments = _b.segments, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new SaveDraftRequest();
                request.setOrderId(orderId);
                request.setSegmentsList(segments);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "saveDraft",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var updateAdvancedOptionsRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var orderId = _b.orderId, translationCategory = _b.translationCategory, translationType = _b.translationType, maxLength = _b.maxLength, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UpdateAdvancedOptionsRequest();
                request.setOrderId(orderId);
                request.setTranslationCategory(translationCategory);
                request.setTranslationType(OrderTranslationType[translationType]);
                if (maxLength !== undefined) {
                    request.setMaxLength(maxLength);
                }
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "updateAdvancedOptions",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getUnreadStatusRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var role = _b.role, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new UnreadStatusRequest();
                request.setRole(role);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "getUnreadStatus",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listActionRequiredOrdersTranslatorRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, metadata;
    var limit = _b.limit, offset = _b.offset, field = _b.field, order = _b.order, search = _b.search, locale = _b.locale, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListActionRequiredOrdersTranslatorRequest();
                page = new Page();
                page.setOffset(offset);
                page.setLimit(limit);
                request.setPage(page);
                request.setField(SortOrderField[field]);
                request.setOrder(SortOrder[order]);
                request.setSearch(search);
                request.setLocale(locale);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "listActionRequiredOrdersTranslator",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var createOrderRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, advancedOptions, cid, newLanguageList, metadata;
    var translationService = _b.translationService, sourceText = _b.sourceText, sourceLanguage = _b.sourceLanguage, hint = _b.hint, targetLanguageList = _b.targetLanguageList, attachments = _b.attachments, discountCode = _b.discountCode, waitOrdersGroupToNotified = _b.waitOrdersGroupToNotified, advancedCategoryValue = _b.advancedCategoryValue, maxTranslationsLength = _b.maxTranslationsLength, type = _b.type, draftId = _b.draftId, needApplyTmPmDiscount = _b.needApplyTmPmDiscount, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new CreateOrderRequest();
                advancedOptions = new AdvancedOptions();
                cid = getCid();
                request.setSourceText(sourceText);
                request.setSourceLanguage(sourceLanguage);
                request.setHint(hint);
                newLanguageList = targetLanguageList.reduce(function (accumulator, element) {
                    accumulator.push(element.value);
                    return accumulator;
                }, []);
                request.setTargetLanguageList(newLanguageList);
                request.setAttachmentsList(attachments);
                //@ts-ignore
                request.setCid(cid);
                request.setDiscountCode(discountCode);
                request.setWaitordersgrouptonotified(waitOrdersGroupToNotified);
                !!draftId && request.setDraftId(draftId);
                request.setNeedApplyTmPmDiscount(needApplyTmPmDiscount);
                advancedOptions.setTranslationCategory(advancedCategoryValue);
                advancedOptions.setTranslationType(OrderTranslationType[type]);
                advancedOptions.setMaxLength(maxTranslationsLength);
                request.setAdvancedOptions(advancedOptions);
                request.setTranslationService(translationService);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "createOrder",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var createOrderFileRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var createOrderFileRequest, createOrderRequest, advancedOptions, cid, newLanguageList, metadata;
    var translationService = _b.translationService, uuids = _b.uuids, sourceLanguage = _b.sourceLanguage, hint = _b.hint, targetLanguageList = _b.targetLanguageList, attachments = _b.attachments, type = _b.type, discountCode = _b.discountCode, maxTranslationsLength = _b.maxTranslationsLength, advancedCategoryValue = _b.advancedCategoryValue, waitOrdersGroupToNotified = _b.waitOrdersGroupToNotified, draftId = _b.draftId, needApplyTmPmDiscount = _b.needApplyTmPmDiscount, token = _b.token;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                createOrderFileRequest = new CreateOrderFileRequest();
                createOrderRequest = new CreateOrderRequest();
                advancedOptions = new AdvancedOptions();
                cid = getCid();
                advancedOptions.setTranslationCategory(advancedCategoryValue);
                advancedOptions.setTranslationType(OrderTranslationType[type]);
                advancedOptions.setMaxLength(maxTranslationsLength);
                createOrderRequest.setSourceLanguage(sourceLanguage);
                createOrderRequest.setHint(hint);
                newLanguageList = targetLanguageList.reduce(function (accumulator, element) {
                    accumulator.push(element.value);
                    return accumulator;
                }, []);
                createOrderRequest.setTargetLanguageList(newLanguageList);
                createOrderRequest.setAttachmentsList(attachments);
                //@ts-ignore
                createOrderRequest.setCid(cid);
                createOrderRequest.setDiscountCode(discountCode);
                createOrderRequest.setAdvancedOptions(advancedOptions);
                createOrderRequest.setNeedApplyTmPmDiscount(needApplyTmPmDiscount);
                createOrderFileRequest.setFileUuid(uuids);
                createOrderFileRequest.setRequest(createOrderRequest);
                createOrderRequest.setWaitordersgrouptonotified(waitOrdersGroupToNotified);
                !!draftId && createOrderRequest.setDraftId(draftId);
                createOrderRequest.setTranslationService(translationService);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "createOrderFile",
                        protoRequest: createOrderFileRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listOrdersWithDraftAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, statusesList, metadata;
    var token = _b.token, limit = _b.limit, offset = _b.offset, search = _b.search, sourceLanguage = _b.sourceLanguage, targetLanguage = _b.targetLanguage, format = _b.format, locale = _b.locale, sortOrder = _b.sortOrder, sortField = _b.sortField, status = _b.status, workspace = _b.workspace;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListOrdersRequest();
                page = new Page();
                statusesList = getCorrectStatusesList(status);
                page.setLimit(limit);
                page.setOffset(offset);
                request.setPage(page);
                request.setSearch(search ? search.toLowerCase() : "");
                request.setSourceLanguagesList(sourceLanguage);
                request.setTargetLanguagesList(targetLanguage);
                request.setFormat(format);
                request.setLocale(locale);
                request.setField(SortOrderField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                request.setStatusesList(statusesList);
                workspace && request.setWorkspaceName(workspace);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "listOrdersWithDraftAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listOrdersWithDraftTranslatorRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, statusesList, metadata;
    var token = _b.token, limit = _b.limit, offset = _b.offset, search = _b.search, sourceLanguage = _b.sourceLanguage, targetLanguage = _b.targetLanguage, format = _b.format, locale = _b.locale, sortOrder = _b.sortOrder, sortField = _b.sortField, status = _b.status;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListOrdersRequest();
                page = new Page();
                statusesList = getCorrectStatusesList(status);
                page.setLimit(limit);
                page.setOffset(offset);
                request.setPage(page);
                request.setSearch(search ? search.toLowerCase() : "");
                request.setSourceLanguagesList(sourceLanguage);
                request.setTargetLanguagesList(targetLanguage);
                request.setFormat(format);
                request.setLocale(locale);
                request.setField(SortOrderField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                request.setStatusesList(statusesList);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "listOrdersWithDraftTranslator",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listOrdersTranslatorRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, statusesList, metadata;
    var token = _b.token, limit = _b.limit, offset = _b.offset, search = _b.search, sourceLanguage = _b.sourceLanguage, targetLanguage = _b.targetLanguage, format = _b.format, locale = _b.locale, sortOrder = _b.sortOrder, sortField = _b.sortField, status = _b.status;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListOrdersRequest();
                page = new Page();
                statusesList = getCorrectStatusesList(status);
                page.setLimit(limit);
                page.setOffset(offset);
                request.setPage(page);
                request.setSearch(search ? search.toLowerCase() : "");
                request.setSourceLanguagesList(sourceLanguage);
                request.setTargetLanguagesList(targetLanguage);
                request.setFormat(format);
                request.setLocale(locale);
                request.setField(SortOrderField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                request.setStatusesList(statusesList);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "listOrdersTranslator",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listOrdersAdminRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, statusesList, metadata;
    var token = _b.token, limit = _b.limit, offset = _b.offset, search = _b.search, sourceLanguage = _b.sourceLanguage, targetLanguage = _b.targetLanguage, format = _b.format, locale = _b.locale, sortOrder = _b.sortOrder, sortField = _b.sortField, status = _b.status, createdAfter = _b.createdAfter, createdBefore = _b.createdBefore, completedAfter = _b.completedAfter, completedBefore = _b.completedBefore, workspace = _b.workspace;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListOrdersRequest();
                page = new Page();
                statusesList = getCorrectStatusesList(status);
                page.setLimit(limit);
                page.setOffset(offset);
                request.setPage(page);
                request.setSearch(search ? search.toLowerCase() : "");
                request.setSourceLanguagesList(sourceLanguage);
                request.setTargetLanguagesList(targetLanguage);
                request.setFormat(format);
                request.setLocale(locale);
                request.setField(SortOrderField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                request.setStatusesList(statusesList);
                request.setCreatedBefore(createdBefore);
                request.setCreatedAfter(createdAfter);
                request.setCompletedBefore(completedBefore);
                request.setCompletedAfter(completedAfter);
                workspace && request.setWorkspaceName(workspace);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "listOrdersAdmin",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listOrdersCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, page, statusesList, metadata;
    var token = _b.token, limit = _b.limit, offset = _b.offset, search = _b.search, sourceLanguage = _b.sourceLanguage, targetLanguage = _b.targetLanguage, format = _b.format, locale = _b.locale, sortOrder = _b.sortOrder, sortField = _b.sortField, status = _b.status;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListOrdersRequest();
                page = new Page();
                statusesList = getCorrectStatusesList(status);
                page.setLimit(limit);
                page.setOffset(offset);
                request.setPage(page);
                request.setSearch(search ? search.toLowerCase() : "");
                request.setSourceLanguagesList(sourceLanguage);
                request.setTargetLanguagesList(targetLanguage);
                request.setFormat(format);
                request.setLocale(locale);
                request.setField(SortOrderField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                request.setStatusesList(statusesList);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "listOrdersCustomer",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var listCompletedOrdersCustomerRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, listCompletedOrdersCustomerRequest, page, statusesList, metadata;
    var token = _b.token, limit = _b.limit, offset = _b.offset, search = _b.search, sourceLanguage = _b.sourceLanguage, targetLanguage = _b.targetLanguage, format = _b.format, locale = _b.locale, sortOrder = _b.sortOrder, sortField = _b.sortField, status = _b.status, archived = _b.archived, createdAfter = _b.createdAfter, createdBefore = _b.createdBefore, completedAfter = _b.completedAfter, completedBefore = _b.completedBefore;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new ListOrdersRequest();
                listCompletedOrdersCustomerRequest = new ListCompletedOrdersCustomerRequest();
                page = new Page();
                statusesList = getCorrectStatusesList(status);
                page.setLimit(limit);
                page.setOffset(offset);
                request.setPage(page);
                request.setSearch(search ? search.toLowerCase() : "");
                request.setSourceLanguagesList(sourceLanguage);
                request.setTargetLanguagesList(targetLanguage);
                request.setCreatedBefore(createdBefore);
                request.setCreatedAfter(createdAfter);
                request.setCompletedBefore(completedBefore);
                request.setCompletedAfter(completedAfter);
                request.setFormat(format);
                request.setLocale(locale);
                request.setField(SortOrderField[sortField]);
                request.setOrder(SortOrder[sortOrder]);
                request.setStatusesList(statusesList);
                listCompletedOrdersCustomerRequest.setRequest(request);
                listCompletedOrdersCustomerRequest.setArchived(archived);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                // console.log("listCompletedOrdersCustomerRequest", request.toObject());
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "listCompletedOrdersCustomer",
                        protoRequest: listCompletedOrdersCustomerRequest,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var restartFileBuildRequest = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var token = _b.token, orderId = _b.orderId;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new OrderId();
                request.setOrderId(orderId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "restartFileBuild",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getLexiqaErrors = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var token = _b.token, orderId = _b.orderId, segments = _b.segments;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new CreateProjectRequest();
                request.setOrderId(orderId);
                request.setTargetSegmentsList(segments);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "createProject",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var getSavedLexiqaErrors = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var token = _b.token, projectId = _b.projectId;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new GetProjectRequest();
                request.setLexiProjectId(projectId);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "getProject",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
export var insertAIText = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var request, metadata;
    var token = _b.token, id = _b.id, targetText = _b.targetText;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                request = new InsertAiTextRequest();
                request.setOrderid(id);
                request.setTargetSegmentsList(targetText);
                return [4 /*yield*/, getAuthMetadata(token)];
            case 1:
                metadata = _c.sent();
                return [2 /*return*/, grpcRequest({
                        clientService: OrderServiceClient,
                        methodName: "insertAiText",
                        protoRequest: request,
                        metadata: metadata,
                    })];
        }
    });
}); };
