import React, { useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";

import classnames from "classnames";
import "../../css/deprecated/save-btn.css";

import { ORDER_FORMATS_STATUS } from "../../constants/Order";
import { I18n } from "react-redux-i18n";
import Loading from "../../components/refactored/Loading";

export default function SaveBtn(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [bottomPosition, setBottomPosition] = useState(true);
  const target = useRef(null);

  let classes = classnames("save-btn", props.className);
  const { styleName, style } = props;

  const handleShowPopup = () => {
    const position = target.current.getBoundingClientRect();
    setBottomPosition(!(props.top && document.documentElement.clientHeight - position.top < 100));
    setPopupOpen(true);
  };

  useEffect(() => {
    if (props.setAutoFocus && props.text === I18n.t("TRANSLATOR_APP.DESKTOP_PAGE.DONE_POPUP.YES")) {
      if (target.current) {
        target.current.focus();
      }
    }
  }, [props.setAutoFocus, props.text]);

  const handleHidePopup = () => {
    setPopupOpen(false);
  };

  let stylePopup = props.revisonHover
    ? `popover-block popover-block--right ${props.top && !bottomPosition && "popover-block--top"}`
    : "popover-block";

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <Button
        disabled={!!props.disabled}
        style={style}
        onClick={e => {
          document.activeElement.blur();
          props.onClick && props.onClick(e);
        }}
        styleName={styleName ? styleName : ""}
        className={styleName ? "" : classes}
        onPointerLeave={handleHidePopup}
        onPointerEnter={handleShowPopup}
        ref={target}
      >
        {/*todo: simplify these two props into one: isLoading*/}
        {props.btnAdd ? (
          props.loadingView ? (
            <div style={{ marginLeft: 4, marginRight: 4 }}>
              <Loading display={true} size={16} color={props.spinnerColor || "white"} />
            </div>
          ) : (
            props.text
          )
        ) : (
          props.text
        )}
      </Button>
      {(props.disabled ||
        props.fileFormatStatus === ORDER_FORMATS_STATUS[1] ||
        props.fileFormatStatus === ORDER_FORMATS_STATUS[2] ||
        props.fileFormatStatus === ORDER_FORMATS_STATUS[4]) &&
        target &&
        props.popoverText && (
          <div
            className={stylePopup}
            style={{
              display: popupOpen ? "block" : "none",
            }}
          >
            {props.popoverText}
          </div>
        )}
    </div>
  );
}
