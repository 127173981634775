import { I18n } from "react-redux-i18n";

export const CUSTOMER_CATEGORY_COMPANY = "CUSTOMER_CATEGORY_COMPANY";
export const customerCategoryName = {
  1: { value: "CUSTOMER_CATEGORY_INDIVIDUAL", label: "CUSTOMER_CATEGORY_POPUP.INDIVIDUAL" },
  2: { value: CUSTOMER_CATEGORY_COMPANY, label: "CUSTOMER_CATEGORY_POPUP.COMPANY" },
  3: { value: "CUSTOMER_CATEGORY_TRANSLATOR", label: "CUSTOMER_CATEGORY_POPUP.TRANSLATOR" },
};

export const formatCustomerCategoryName = categoryNumber => {
  const category = customerCategoryName[categoryNumber];
  return category ? I18n.t(category.label) : "";
};
