import * as yup from "yup";
import { I18n } from "react-redux-i18n";
export var generateCompanyUrl = function (name) {
    return name
        .toLowerCase()
        .replace(/\s+/g, "")
        .replace(/[^a-z0-9]/g, "");
};
export var validationSchema = function (clientEmail) {
    return yup.object().shape({
        companyName: yup
            .string()
            .required(I18n.t("INPUT_VALIDATIONS.REQUIRED"))
            .max(255, I18n.t("CUSTOMER_APP.WORKSPACE.FORMS_ERRORS.COMPANY_NAME")),
        companyUrl: yup
            .string()
            .required(I18n.t("INPUT_VALIDATIONS.REQUIRED"))
            .matches(/^[a-z0-9]+$/, I18n.t("CUSTOMER_APP.WORKSPACE.FORMS_ERRORS.INVALID_VALUE"))
            .max(45, I18n.t("CUSTOMER_APP.WORKSPACE.FORMS_ERRORS.COMPANY_URL")),
        userEmails: yup
            .array()
            .of(yup.string().email(I18n.t("CUSTOMER_APP.WORKSPACE.FORMS_ERRORS.INVALID_EMAIL")))
            .test("no-user-email", I18n.t("CUSTOMER_APP.WORKSPACE.FORMS_ERRORS.INVITATION"), function (emails) {
            return !(emails === null || emails === void 0 ? void 0 : emails.includes(clientEmail));
        })
            .test("invalid-emails", I18n.t("CUSTOMER_APP.WORKSPACE.FORMS_ERRORS.INVALID_EMAIL"), function (emails) {
            return emails === null || emails === void 0 ? void 0 : emails.every(function (email) { return yup.string().email().isValidSync(email); });
        })
            .test("unique-emails", I18n.t("CUSTOMER_APP.WORKSPACE.FORMS_ERRORS.UNIQUE_EMAILS"), function (emails) {
            var emailsLowerCase = emails === null || emails === void 0 ? void 0 : emails.map(function (elem) { return elem === null || elem === void 0 ? void 0 : elem.toLowerCase(); });
            var uniqueEmails = new Set(emailsLowerCase);
            return uniqueEmails.size === (emails === null || emails === void 0 ? void 0 : emails.length);
        }),
    });
};
export var getValidationMessage = function (serviceError) {
    var message = serviceError.message, parametersList = serviceError.parametersList;
    switch (message) {
        case "Account name exist":
            return I18n.t("CUSTOMER_APP.WORKSPACE.FORMS_ERRORS.ACCOUNT_NAME_EXIST", { name: parametersList[0] });
        case "Account link exist":
            return I18n.t("CUSTOMER_APP.WORKSPACE.FORMS_ERRORS.ACCOUNT_LINK_EXIST", { name: parametersList[0] });
        default:
            return message;
    }
};
