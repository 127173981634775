import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import DefaultSelect from "../../../refactored/Select";
import { workspaceRoleSelectOption, workspaceRoleSelectStyle } from "../../../refactored/Select/workspaceRoles";
import { ROLES_TITLE, rolesOptions } from "./helpers";
import style from "./css/style.module.css";
import { I18n } from "react-redux-i18n";
export var WorkspaceRoles = function (_a) {
    var workspaceRole = _a.workspaceRole, workspaceId = _a.workspaceId, onChange = _a.onChange;
    var _b = useState(rolesOptions().find(function (elem) { return elem.value === workspaceRole; })), role = _b[0], setRole = _b[1];
    var onChangeRole = function (e) {
        onChange({ id: workspaceId, role: e.value });
        setRole(e);
    };
    return (_jsx("div", { children: !workspaceRole ? (_jsx("span", { className: style.roleEmpty, children: I18n.t(ROLES_TITLE[0]) })) : (_jsx(DefaultSelect, { selectStyle: workspaceRoleSelectStyle, selectOptionStyle: workspaceRoleSelectOption, options: rolesOptions(), value: role, onChange: onChangeRole })) }));
};
