var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//@ts-nocheck
import { invokeHttpThunk } from "../../helpers/invokeThunk";
export var uploadImage = function (image, type, name, width, height, cursorWatch) {
    var requestName = "UPLOAD_FILE";
    var imagesJson = {
        type: "image/png",
        content: image,
        width: width,
        height: height,
        filename: name,
    };
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/attachments/upload",
            method: token ? "post" : "put",
            type: "image/png",
            body: JSON.stringify(imagesJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, cursorWatch, false, false);
    };
};
export var deleteImage = function (id, cursorWatch, notAuth) {
    var requestName = "DELETE_FILE";
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: token && !notAuth ? "/attachments/delete/" + id : "/attachments/remove/" + id,
            method: "DELETE",
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, cursorWatch, false, false);
    };
};
export var uploadGoogleFile = function (url, orderId) {
    var requestName = "UPLOAD_GOOGLE_FILE";
    console.log("uploadGoogleFile url", url);
    console.log("uploadGoogleFile orderId", orderId);
    //TODO type подкидывать в соответствии с прототипами
    var fileDataJson = {
        type: "GOOGLE_DOC",
        order_id: orderId ? orderId : "",
        file_url: url,
    };
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files",
            method: "post",
            body: JSON.stringify(fileDataJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, false);
    };
};
var uploadFileOrSpreadsheetStream = function (payload, setAbortController) {
    if (setAbortController === void 0) { setAbortController = null; }
    var EMPTY_STREAM_MESSAGE = "☢☣☯☮☣☬☪";
    var OK_STREAM_RESPONSE = "$$$";
    return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
        var controller, response, reader, data, _a, done, value, valueAsString, valueWithoutEmptyMessages, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 5, , 6]);
                    controller = new AbortController();
                    setAbortController && setAbortController(controller);
                    return [4 /*yield*/, fetch("".concat(process.env.FILE_SERVICES_URL).concat(payload.url), {
                            method: "post",
                            body: payload.body,
                            signal: controller === null || controller === void 0 ? void 0 : controller.signal,
                        })];
                case 1:
                    response = _b.sent();
                    reader = response.body.getReader();
                    data = { isError: true, value: "" };
                    _b.label = 2;
                case 2:
                    if (!true) return [3 /*break*/, 4];
                    return [4 /*yield*/, reader.read()];
                case 3:
                    _a = _b.sent(), done = _a.done, value = _a.value;
                    if (done) {
                        resolve(data);
                        return [3 /*break*/, 4];
                    }
                    if (value) {
                        valueAsString = new TextDecoder().decode(value);
                        valueWithoutEmptyMessages = valueAsString.replaceAll(EMPTY_STREAM_MESSAGE, "");
                        if (valueWithoutEmptyMessages.startsWith(OK_STREAM_RESPONSE)) {
                            valueWithoutEmptyMessages = valueWithoutEmptyMessages.replace(OK_STREAM_RESPONSE, "");
                            data.isError = false;
                        }
                        data.value = valueWithoutEmptyMessages;
                    }
                    return [3 /*break*/, 2];
                case 4: return [3 /*break*/, 6];
                case 5:
                    e_1 = _b.sent();
                    reject(e_1);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); });
};
export var uploadGoogleSheets = function (url, orderId, setAbortController) {
    var fileDataJson = {
        type: "GOOGLE_SPREADSHEET",
        //order_id: orderId ? orderId : "",
        file_url: url,
    };
    if (orderId) {
        fileDataJson["order_id"] = orderId;
    }
    var payload = {
        url: "/files/select",
        body: JSON.stringify(fileDataJson),
    };
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, uploadFileOrSpreadsheetStream(payload, setAbortController)];
        });
    }); };
};
export var createOrderPopup = function (data) {
    var payload = {
        url: "/files/select",
        body: JSON.stringify(data),
    };
    return function (dispatch, getState) {
        return uploadFileOrSpreadsheetStream(payload);
    };
};
export var uploadGoogleSheetsTable = function (url, arrData, uuid) {
    var requestName = "UPLOAD_GOOGLE_SEHHETS";
    console.log("uploadGoogleSheetsTable", url);
    console.log("uploadGoogleSheetsTable", arrData);
    var fileDataJson = {
        file_url: url,
        type: "GOOGLE_SPREADSHEET",
        selected: arrData,
        uuid: uuid && "",
    };
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files",
            method: "post",
            body: JSON.stringify(fileDataJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, false);
    };
};
export var uploadGoogleFileToTarget = function (url, orderId, docsType) {
    var requestName = "UPLOAD_GOOGLE_FILE_TO_TARGET";
    console.log("uploadGoogleFile url", url);
    console.log("uploadGoogleFile orderId", orderId);
    //TODO type подкидывать в соответствии с прототипами
    var fileDataJson = {
        type: docsType,
        order_id: orderId ? orderId : "",
        file_url: url,
    };
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files/target",
            method: "post",
            body: JSON.stringify(fileDataJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, true);
    };
};
export var downloadGoogleFile = function (orderId) {
    var requestName = "DOWNLOAD_GOOGLE_FILE";
    console.log("DOWNLOAD_GOOGLE_FILE", orderId);
    var fileDataJson = {
        order_id: orderId,
        is_source_file: false,
    };
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files/export",
            method: "post",
            body: JSON.stringify(fileDataJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, true, true);
    };
};
export var deleteGoogleFile = function (id) {
    var requestName = "DELETE_GOOGLE_FILE";
    console.log("deleteGoogleFile id", id);
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files/".concat(id),
            method: "delete",
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, true);
    };
};
export var uploadFileFromComputer = function (file, orderId, toTarget, cursorWatch) {
    if (cursorWatch === void 0) { cursorWatch = false; }
    var requestName = "UPLOAD_FILE_FROM_COMPUTER";
    var fileDataJson = {
        type: file.type,
        filename: file.name,
        content: file.content,
        uuid: file.uuid || undefined, //for PopupPreview
        encoding: file.encoding || undefined, //for PopupPreview
        parser_info: file.parserInfo || undefined, //for .csv,  PopupCSVSelector
    };
    if (orderId) {
        fileDataJson["order_id"] = orderId;
    }
    if (file.selected) {
        fileDataJson["selected"] = file.selected;
    }
    var json = JSON.stringify(fileDataJson);
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: toTarget ? "/files/target" : "/files",
            method: "post",
            body: json,
        };
        console.log("requestOptions", requestOptions);
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, cursorWatch);
    };
};
export var downloadOrderFile = function (orderId, isSourceFile, fileUuid) {
    if (isSourceFile === void 0) { isSourceFile = false; }
    if (fileUuid === void 0) { fileUuid = null; }
    var requestName = "DOWNLOAD_ORDER_FILE";
    // console.log("DOWNLOAD_ORDER_FILE orderId", orderId);
    // console.log("DOWNLOAD_ORDER_FILE isSourceFile", isSourceFile);
    var fileDataJson = {
        order_id: orderId,
        is_source_file: isSourceFile,
        uuid: fileUuid,
    };
    // console.log("DOWNLOAD_ORDER_FILE fileDataJson", fileDataJson);
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files/export",
            method: "post",
            body: JSON.stringify(fileDataJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, true, true);
    };
};
export var downloadInvoiceFile = function (paymentId, is_invoice) {
    var requestName = "DOWNLOAD_INVOICE_FILE";
    var fileDataJson = {
        payment_id: paymentId,
        is_invoice: is_invoice,
    };
    console.log("DOWNLOAD_INVOICE_FILE fileDataJson", fileDataJson);
    return function (dispatch, getState) {
        var token = getState().app.token;
        var requestOptions = {
            url: "/files/export/payment",
            method: "post",
            body: JSON.stringify(fileDataJson),
        };
        return invokeHttpThunk(dispatch, requestOptions, requestName, token, true, true, false);
    };
};
