//[WIP] RENAME TO SESSION STORAGE
var LocalStorageService = /** @class */ (function () {
    function LocalStorageService() {
        this.workspace_key = "workspace_id";
    }
    LocalStorageService.prototype.setValueByKey = function (id, value) {
        return sessionStorage.setItem(id, value);
    };
    LocalStorageService.prototype.getByKey = function (id) {
        return sessionStorage.getItem(id);
    };
    LocalStorageService.prototype.setWorkspaceId = function (id) {
        return this.setValueByKey(this.workspace_key, id);
    };
    LocalStorageService.prototype.getWorkspaceId = function () {
        return this.getByKey(this.workspace_key);
    };
    return LocalStorageService;
}());
export var localStorageService = new LocalStorageService();
