export const workspaceRoleSelectOption = props => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 0, // Отступы внутри опции
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 300, // Жирный текст для выбранного элемента
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: props.isSelected ? "default" : "pointer",
  };
};

export const workspaceRoleSelectStyle = props => {
  return {
    control: (base, { menuIsOpen }) => ({
      ...base,
      height: 30, // Общая высота компонента
      minHeight: 20,
      width: 120, // Ширина компонента
      border: "none",
      borderRadius: 0, // Без скруглений
      boxShadow: "none", // Убираем стандартную тень
      backgroundColor: "transparent", // Прозрачный фон
      "&:hover": "none",
    }),

    placeholder: base => ({
      ...base,
      color: "#424242", // Черный цвет текста
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      margin: 0,
    }),

    singleValue: base => ({
      ...base,
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400, // Чуть более жирный текст выбранного значения
      color: "#424242",
      margin: 0,
      textAlign: "end",
    }),
    valueContainer: base => ({ ...base, padding: "2px 0" }),
    dropdownIndicator: (base, { selectProps }) => {
      const { menuIsOpen } = selectProps;
      return {
        ...base,
        padding: 0,
        transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
        left: menuIsOpen && 6,
        position: "relative",
        "&:hover": {
          color: "#000000",
        },
      };
    },

    indicatorSeparator: () => ({
      display: "none", // Убираем разделитель между стрелкой и текстом
    }),

    menu: base => ({
      ...base,
      marginTop: 4,
      borderRadius: 6, // Убираем скругление у выпадающего меню
      border: "none", // Граница выпадающего списка
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Легкая тень
      backgroundColor: "#FFFFFF", // Белый фон
      width: 166,
      right: 5,
    }),

    menuList: base => ({
      ...base,
      padding: 0,
      borderRadius: 0,
      backgroundColor: "#FFFFFF",
      padding: "8px 0",
    }),

    option: (base, { isSelected, isFocused }) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "6px 16px", // Отступы внутри опций
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400, // Более жирный текст для выбранного элемента
      color: isSelected ? "#424242" : isFocused ? "#424242" : "#424242", // Цвет текста
      backgroundColor: isSelected ? "#E3F2FD" : "#FFFFFF", // Цвет фона при наведении или выборе
      cursor: !isSelected && "pointer",
      "&:hover": {
        backgroundColor: "#E3F2FD",
        color: !isSelected && "#1e88e5",
      },
    }),
  };
};
