import React, { useEffect, useState } from "react";
import Popup from "../../../elements/Popup";
import style from "./css/style.module.css";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { Input } from "../../Input/Input";
import { BUTTON_COLOR_SCHEME, BUTTON_SIZE, BUTTON_TYPES, Button } from "../../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  createWorkspaceAction,
  getWorkspaceAction,
} from "../../../../thunks/newThunks/workspace/workspaceAsyncActions";
import { generateCompanyUrl, validationSchema, getValidationMessage } from "./helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import Question from "../../Title/components";
import { useNavigate } from "react-router";
import { getMeAction } from "../../../../thunks/newThunks/user/userAsyncActions";
import { TagInput } from "../../TagInput/TagInput";
import { I18n } from "react-redux-i18n";

export const PopupCreateWorkspace = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const customerEmail = useSelector(state => state.me.me.data.email);
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    reset,
  } = useForm({ resolver: yupResolver(validationSchema(customerEmail)) });

  //[WIP] need to fix, its bad practice to reset form, need to change popup wrapper
  useEffect(() => {
    return () => reset();
  }, [isOpen]);

  const onSubmit = async data => {
    try {
      const workspace = await dispatch(
        createWorkspaceAction({
          name: data.companyName,
          link: data.companyUrl,
          invites: data.userEmails,
        })
      );
      await dispatch(getMeAction());
      await dispatch(getWorkspaceAction({ id: workspace.id }));
      handleNavigation(workspace.link);
      onClose();
    } catch (e) {
      handleErrors(e);
    }
  };
  const handleNavigation = workspaceLink => {
    const currentPathArray = window.location.pathname.split("/");
    currentPathArray.splice(1, 1, workspaceLink);
    navigate(currentPathArray.join("/"));
  };
  const handleErrors = error => {
    const { serviceError } = error;
    if (serviceError) {
      serviceError.validationError.invalidFieldsList.forEach(elem =>
        setError(elem.field, { message: getValidationMessage(elem) })
      );
    }
  };
  const [isCompanyUrlModified, setIsCompanyUrlModified] = useState(false);

  return (
    <Popup isOpen={isOpen} onClose={onClose} className={style.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.formWrapper}>
          <p className={style.formTitle}>{I18n.t("CUSTOMER_APP.WORKSPACE.CREATE_NEW")}</p>
          <Input
            {...register("companyName")}
            id={"companyName"}
            onBlur={e => {
              const name = e.target.value;
              if (!isCompanyUrlModified && name) {
                const generatedUrl = generateCompanyUrl(name);
                setValue("companyUrl", generatedUrl);
              }
            }}
            error={errors?.["companyName"]?.message}
            label={I18n.t("CUSTOMER_APP.WORKSPACE.SETTINGS.PERSONAL.WORKSPACE_NAME")}
            name={"companyName"}
          />

          <Input
            {...register("companyUrl")}
            id={"companyUrl"}
            error={errors?.["companyUrl"]?.message}
            label={I18n.t("CUSTOMER_APP.WORKSPACE.SETTINGS.PERSONAL.WORKSPACE_LINK")}
            name={"companyUrl"}
            fixedValue="app.nitrotranslate.com/"
            onChange={e => {
              setIsCompanyUrlModified(true);
            }}
          />
          <Controller
            name={"userEmails"}
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <TagInput
                value={value}
                onChange={onChange}
                error={errors?.["userEmails"]?.message}
                label={
                  <div className={style.tagLabelWrapper}>
                    <span>{I18n.t("CUSTOMER_APP.WORKSPACE.SETTINGS.NEW_MEMBERS.INVITE_TITLE")}</span>{" "}
                    <Question question={I18n.t("CUSTOMER_APP.WORKSPACE.SETTINGS.NEW_MEMBERS.TEXT")} />
                  </div>
                }
              />
            )}
          />
        </div>
        <div className={style.buttonContainer}>
          <Button
            colorScheme={BUTTON_COLOR_SCHEME.accept}
            label={I18n.t("CUSTOMER_APP.GLOSSARIES_PAGE.NEW_GLOSSARY_SECTION.CREATE")}
            name="save"
            size={BUTTON_SIZE.medium}
            type={BUTTON_TYPES.submit}
          />
          <Button
            colorScheme={BUTTON_COLOR_SCHEME.closeTransparent}
            label={I18n.t("ORDERS_PAGE.CANCEL")}
            name="close"
            size={BUTTON_SIZE.small}
            type={BUTTON_TYPES.button}
            onClick={onClose}
          />
        </div>
      </form>
    </Popup>
  );
};
