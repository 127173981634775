import { defineAction } from "redux-define";
import { ERROR, REQUEST, SUCCESS } from "../constants/RequestStatuses";
import { newHttpRequest } from "./httpRequest";
import * as jose from "jose";
import { setCookie, getCookie } from "./common";
import { setToken } from "../actions/App";
import { POSITIVE_CODES } from "./httpRequest";
import { generateAccessToken } from "../services/AuthServices/requests";
import { LOGOUT_RESPONSE_CODES } from "../components/refactored/AuthProvider/AuthProvider";
import { localStorageService } from "../services/LocalStorageService";

export const invokeHttpThunk = async (
  dispatch,
  requestOptions,
  thunkName,
  token,
  cursorWatch,
  isReturnFullResponse,
  isFileService = true,
  setAbortController
) => {
  const thunkAction = defineAction(thunkName, [ERROR, SUCCESS, REQUEST]);
  dispatch({ type: thunkAction.REQUEST });
  let newToken = token;
  const savedRefreshToken = getCookie("refresh_token");
  try {
    if (newToken && getCookie("auth_token") && savedRefreshToken) {
      const claims = jose.decodeJwt(newToken);
      const currentDate = Math.round(Date.now() / 1000);
      if ((claims.exp && claims.exp <= currentDate) || !claims.exp) {
        await generateAccessToken(savedRefreshToken)
          .then(accessToken => {
            setCookie("auth_token", accessToken.accessToken, 3650);
            newToken = accessToken.accessToken;
          })
          .catch(e => {
            const shouldLogout = LOGOUT_RESPONSE_CODES.includes(e?.code);

            if (shouldLogout) {
              setCookie("auth_token", "", -10);
              localStorageService.setWorkspaceId("");
              setCookie("refresh_token", "", -10);
              window.location.href = "/login";
            }
          });

        dispatch(setToken(getCookie("auth_token")));
      }
    }
    const data = await newHttpRequest(
      requestOptions,
      newToken,
      dispatch,
      cursorWatch,
      isFileService,
      setAbortController
    );
    console.log("invokeHttpThunk data", data);

    if (isReturnFullResponse) {
      return data;
    } else {
      return POSITIVE_CODES.includes(data.status)
        ? data.text()
        : dispatch({
            type: thunkAction.ERROR,
            payload: { status: data.status, text },
          });
    }
  } catch (e) {
    console.error("e", e);
    dispatch({ type: thunkAction.ERROR, payload: e });
    throw e;
  }
};
