var _a;
import { I18n } from "react-redux-i18n";
import { WorkspaceRole } from "@alconost/nitro-services-api/src/proto/workspace_service_pb";
export var ROLES_TITLE = (_a = {},
    _a[WorkspaceRole.UNKNOWN_WORKSPACE_ROLE] = "MEMBERS_TABLE.PENDING",
    _a[WorkspaceRole.WORKSPACE_ADMIN] = "MEMBERS_TABLE.ADMIN_ROLE",
    _a[WorkspaceRole.WORKSPACE_MEMBER] = "MEMBERS_TABLE.MEMBER_ROLE",
    _a);
export var rolesOptions = function () {
    return [
        { value: WorkspaceRole.WORKSPACE_ADMIN, label: I18n.t(ROLES_TITLE[WorkspaceRole.WORKSPACE_ADMIN]) },
        { value: WorkspaceRole.WORKSPACE_MEMBER, label: I18n.t(ROLES_TITLE[WorkspaceRole.WORKSPACE_MEMBER]) },
    ];
};
