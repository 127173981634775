import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setRefreshToken, setTitle } from "../../../actions/App";
// eslint-disable-next-line no-unused-vars
import signup from "./css/signup-success.css";
import { I18n } from "react-redux-i18n";
import NitroButton from "../../../components/refactored/GreenButton";
import { translatedTextWithLink } from "../../../helpers/translatedTextWithLink";
import { resendConfirmEmailAction, confirmSignupAction } from "../../../thunks/newThunks/signup/signupAsyncActions";
import { emailAction } from "../../../thunks/newThunks/signin/signinAsyncActions";
import FormInput from "../../../components/refactored/FormInput";
import HtmlTranslate from "../../../components/HtmlTranslate";
import { getCouponFromUrl, getDiscountFromUrl, getWorkspaceLinkFromUrl } from "../../../helpers/getCouponFromUrl";
import FormError from "../../../components/errors/FormError";
import { setCookie, setCookiePromise } from "../../../helpers/common";
import ErrorMessage from "../../../components/refactored/ErrorMessage";
import { withRouter } from "../../CustomerApp";
import { getMeAction } from "../../../thunks/newThunks/user/userAsyncActions";

const INVALID_SECURITY_TOKEN = 3;

class SignupSuccess extends Component {
  static propTypes = {
    signup: PropTypes.object.isRequired,
    setTitle: PropTypes.func.isRequired,
  };

  state = {
    code: "",
    codeStatus: "",
    invalidCode: false,
  };

  componentDidMount() {
    !this.props.isNotSetTitle && this.props.setTitle(I18n.t("SIGNUP_SUCCESS_PAGE.PAGE_TITLE"));
  }

  handleSubmit = e => {
    const { code } = this.state;
    const { confirmSignupCode, signup, fetchAuth } = this.props;
    const isProd = process.env.NODE_ENV === "production";
    e.preventDefault();

    if (code) {
      this.setState(
        {
          codeStatus: "",
          invalidCode: false,
        },
        () => {
          if (signup.form) {
            const { email } = signup.form;
            confirmSignupCode(email, code, true)
              .then(response => {
                if (response.status === INVALID_SECURITY_TOKEN) {
                  this.setState({
                    invalidCode: true,
                  });
                } else {
                  fetchAuth(signup.form, getCouponFromUrl()).then(({ refreshToken, token }) => {
                    setCookie("refresh_token", refreshToken, 3650);
                    setRefreshToken(refreshToken);
                    setCookiePromise("auth_token", token, 3650).then(() => {
                      this.props.getMe(token).then(res => {
                        window?.dataLayer?.push({
                          event: "nitro_signup",
                          nitro_user_id: res.id,
                          is_prod: isProd,
                        });
                        const workspaceLink = getWorkspaceLinkFromUrl();
                        if (workspaceLink) {
                          window.location = `/?invite=${workspaceLink}`;
                          return;
                        }
                        window.location = "/";
                      });
                    });
                  });
                }
              })
              .catch(e => {
                console.log("error", { e });
              });
          }
        }
      );
    } else {
      this.setState({
        codeStatus: "required",
      });
    }
  };

  onChangeCode = e => {
    this.setState({
      code: e.target.value,
    });
  };

  reSendConfirmEmail = email => {
    const { resendConfirmEmail } = this.props;
    resendConfirmEmail(email);
  };

  closeErrorMessage = () => {
    this.setState({
      invalidCode: false,
    });
  };

  render() {
    const { styleSignUpSuccess, styleFormAltSignUpSuccess, styleDescriptionSignUpSuccess } = this.props;
    const { invalidCode } = this.state;
    const { form } = this.props.signup;
    const email = form ? form.email : "";
    const resendLink = translatedTextWithLink(I18n.t("SIGNUP_SUCCESS_PAGE.RESENT_CODE", { email: email }), true);
    const resendLinkElement = (
      <p>
        {" "}
        {resendLink.leftText}
        <a
          onClick={() => this.reSendConfirmEmail(email)}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            borderBottom: "none",
          }}
        >
          {resendLink.link}
        </a>
        {resendLink.rightText}
      </p>
    );

    const workspaceLink = getWorkspaceLinkFromUrl();
    const coupon = getCouponFromUrl();
    const discount = getDiscountFromUrl();
    const signInUrl = coupon
      ? `/login?coupon=${coupon}${workspaceLink ? "&invite=" + workspaceLink : ""}`
      : discount
        ? `/login?discount=${discount}${workspaceLink ? "&invite=" + workspaceLink : ""}`
        : workspaceLink
          ? `/login?invite=${workspaceLink}`
          : "/login";
    return (
      <Fragment>
        {email ? (
          <Fragment>
            <FormError
              onClose={this.closeErrorMessage}
              message={I18n.t("SIGNUP_SUCCESS_PAGE.INVALID_CODE_ERROR_MESSAGE")}
              display={invalidCode}
            />
            <div styleName={"signup.formContainer"} style={styleSignUpSuccess}>
              <div styleName={"signup.description"} style={styleDescriptionSignUpSuccess}>
                <HtmlTranslate value="SIGNUP_SUCCESS_PAGE.SUCCESS_MESSAGE" email={email} />
              </div>
              <form onChange={this.onChangeCode} onSubmit={this.handleSubmit}>
                <FormInput
                  type="text"
                  onChange={this.onChangeCode}
                  value={this.state.code}
                  status={this.state.codeStatus}
                />
              </form>

              <NitroButton
                clickHandler={e => this.handleSubmit(e)}
                buttonStyleName={"button.save-button"}
                style={{ minWidth: 115, marginTop: 20 }}
                title={I18n.t("SIGNUP_SUCCESS_PAGE.SUBMIT_CODE_BUTTON")}
              />

              <div styleName={"signup.repeatCode"}>{resendLinkElement}</div>
            </div>
          </Fragment>
        ) : (
          <ErrorMessage
            isHideExit={true}
            textStyle={"message.text-not-found"}
            message={<HtmlTranslate value="ERROR_PAGE.ERROR_MESSAGE" />}
          />
        )}

        <p styleName={"signup.formAlt"} style={styleFormAltSignUpSuccess}>
          <HtmlTranslate value="SIGNUP_PAGE.LOGIN_TEXT" />{" "}
          <Link
            to={signInUrl}
            style={{
              textDecoration: "underline",
              borderBottom: "none",
            }}
          >
            <HtmlTranslate value="SIGNUP_PAGE.LOGIN_LINK" />
          </Link>
        </p>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    signup: state.signup,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTitle: title => {
      dispatch(setTitle(title));
    },
    confirmSignupCode: (email, code) => {
      return dispatch(confirmSignupAction({ email, code }));
    },
    resendConfirmEmail: email => {
      dispatch(resendConfirmEmailAction({ email }));
    },
    fetchAuth: (form, coupon) => {
      return dispatch(emailAction({ email: form.email, password: form.password, coupon }));
    },
    getMe: token => {
      return dispatch(getMeAction(token));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignupSuccess));
