var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { StatusCode } from "grpc-web";
import { getCookie, setCookie } from "../helpers/common";
import { decodeJwt } from "jose";
import { generateAccessToken } from "./AuthServices/requests";
import { deserializeServiceError } from "../helpers/errorDeserialize";
import { localStorageService } from "./LocalStorageService";
import { cursorProgress } from "../settings/cursorWait";
import { LOGOUT_RESPONSE_CODES } from "../components/refactored/AuthProvider/AuthProvider";
var promisify = function (promiseCallback) { return new Promise(promiseCallback); };
var API_ROOT = {
    FILE: process.env.FILE_SERVICES_URL,
    SERVICES: process.env.SERVICES_URL,
};
export var grpcRequest = function (_a) {
    var _b = _a.serviceType, serviceType = _b === void 0 ? "SERVICES" : _b, clientService = _a.clientService, methodName = _a.methodName, protoRequest = _a.protoRequest, metadata = _a.metadata, _c = _a.errorCatch, errorCatch = _c === void 0 ? {} : _c, observable = _a.observable;
    cursorProgress(true);
    return promisify(function (resolve, reject) {
        var clientServiceRequest = new clientService(API_ROOT[serviceType]);
        // @ts-ignore
        var serviceRequest = clientServiceRequest[methodName](protoRequest, metadata, function (error, response) {
            cursorProgress(false);
            if (!error) {
                resolve(response.toObject());
            }
            else {
                var code_1 = error.code, message_1 = error.message, metadata_1 = error.metadata;
                var isUnauthorized = code_1 === StatusCode.UNAUTHENTICATED;
                var customError_1 = code_1 === StatusCode.INTERNAL;
                var isNotOk = code_1 !== 0;
                var unhandledError = function () {
                    //[WIP] вынести в отдельную функцию SentryCaptureError
                    //Sentry.captureMessage(`Service ERROR with code ${code}. Message ${message}`);
                    //[WIP]вывести нотифайку
                    if (customError_1) {
                        reject({
                            code: code_1,
                            error: error,
                            metadata: metadata_1,
                            serviceError: deserializeServiceError({ msg: message_1, methodName: methodName, metadata: metadata_1 }),
                        });
                    }
                    else {
                        reject({ code: code_1, error: error, metadata: metadata_1 });
                    }
                };
                if (isUnauthorized) {
                    // window?.localStorage.setItem("log_for_logout", `newGrpcUtils methodName: ${methodName}`);
                    window.location.href = "/login";
                    setCookie();
                }
                else if (isNotOk) {
                    if (errorCatch === null || errorCatch === void 0 ? void 0 : errorCatch.customErrorHandler) {
                        var customErrorHandler = errorCatch.customErrorHandler;
                        customErrorHandler();
                    }
                    else {
                        unhandledError();
                    }
                }
            }
        });
        serviceRequest.on("status", function (status) {
            var metadata = JSON.stringify(status.metadata);
            if (localStorage.getItem("buildData") !== metadata) {
                localStorage.setItem("buildData", metadata);
            }
        });
        if (observable) {
            observable.subscribe(function () { return serviceRequest.cancel(); });
        }
    });
};
export var getAuthMetadata = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var workspaceId, newToken, claims, currentDate, refreshToken, accessToken, e_1, shouldLogout, authToken;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                workspaceId = localStorageService.getWorkspaceId();
                if (!token) {
                    //[workspace]
                    return [2 /*return*/, {
                            authorization: "bearer ",
                            "Workspace-Id": workspaceId ? workspaceId : "",
                        }];
                }
                newToken = token;
                claims = decodeJwt(newToken);
                currentDate = Math.round(Date.now() / 1000);
                refreshToken = getCookie("refresh_token");
                if (!(((claims.exp && claims.exp <= currentDate) || !claims.exp) && refreshToken)) return [3 /*break*/, 4];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, generateAccessToken(refreshToken)];
            case 2:
                accessToken = (_a.sent()).accessToken;
                newToken = accessToken;
                setCookie("auth_token", accessToken, 3650);
                return [3 /*break*/, 4];
            case 3:
                e_1 = _a.sent();
                shouldLogout = LOGOUT_RESPONSE_CODES.includes(e_1 === null || e_1 === void 0 ? void 0 : e_1.code);
                if (shouldLogout) {
                    setCookie("auth_token", "", -10);
                    localStorageService.setWorkspaceId("");
                    setCookie("refresh_token", "", -10);
                }
                window.location.href = "/login";
                return [3 /*break*/, 4];
            case 4:
                authToken = "bearer " + newToken;
                //[workspace]
                return [2 /*return*/, {
                        authorization: authToken,
                        "Workspace-Id": workspaceId ? workspaceId : "",
                    }];
        }
    });
}); };
