import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import history from "history/browser";
import LoginForm from "../../components/forms/LoginForm";
import FormError from "../../components/errors/FormError";
import HtmlTranslate from "../../components/HtmlTranslate";

import { APP_DISABLED } from "../../constants/App";

import {
  AUTH_COMPLETED,
  AUTH_ERROR,
  UNCONFIRMED_AUTH,
  EMAIL_FALLBACK,
  FACEBOOK_AUTH_REQUESTED,
  FACEBOOK_AUTH_VALIDATED,
  GOOGLE_AUTH_VALIDATED,
  GOOGLE_AUTH_WITH_CODE_REQUESTED,
} from "../../constants/Signin";

import { ME_FETCHED } from "../../constants/User";

import { connect } from "react-redux";

import { fetchRequestFacebookAuth } from "../../thunks/Facebook";

import { emailAction, facebookAction, googleAction } from "../../thunks/newThunks/signin/signinAsyncActions";

import { setTitle, validateForm } from "../../actions/App";

import { failAuth, initAuth } from "../../actions/Signin";

import { getCookie, getParameterByName, setCookie } from "../../helpers/common";

import { I18n } from "react-redux-i18n";

import moment from "moment";
import { getCouponFromUrl, getDiscountFromUrl, getWorkspaceLinkFromUrl } from "../../helpers/getCouponFromUrl";
import { cursorLocalWait } from "../../settings/cursorWait";
import { withRouter } from "../CustomerApp";
import { resendConfirmEmailAction } from "../../thunks/newThunks/signup/signupAsyncActions";
import { fetchMeAction } from "../../thunks/newThunks/user/userAsyncActions";
import { withOutletContext } from "../../hoc/withOutletContext";
import { localStorageService } from "../../services/LocalStorageService";

let win;

export class Login extends Component {
  static propTypes = {
    fetchRequestFacebookAuth: PropTypes.func.isRequired,
    fetchValidateFacebookAuth: PropTypes.func.isRequired,

    fetchAuth: PropTypes.func.isRequired,
    failAuth: PropTypes.func.isRequired,
    resendConfirmEmail: PropTypes.func.isRequired,
    fetchMe: PropTypes.func.isRequired,
    initAuth: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    login: PropTypes.object.isRequired,
    socialSignin: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setTitle: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
  };
  static defaultProps = {
    relativePath: "",
  };
  state = {
    closeLoginError: false,
    closeSocialSigninError: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.socialSignin.status !== prevProps.socialSignin.status) {
      if (this.props.socialSignin.status === EMAIL_FALLBACK) {
        woopra.track("Facebook error message", { env: process.env.SERVER_NAME });
        history.push("/signup?fallbackName=" + this.props.socialSignin.fallbackName + "&coupon=" + getCouponFromUrl());
      }

      if (this.props.socialSignin.status === FACEBOOK_AUTH_REQUESTED) {
        this.props.fetchValidateFacebookAuth(
          this.props.socialSignin.facebookToken,
          prevProps.i18n.locale,
          getCouponFromUrl()
        );
      }

      if (
        this.props.socialSignin.status === FACEBOOK_AUTH_VALIDATED ||
        this.props.socialSignin.status === GOOGLE_AUTH_VALIDATED
      ) {
        this.props.fetchMe(this.props.socialSignin.authToken);
      }

      if (this.props.socialSignin.status === GOOGLE_AUTH_WITH_CODE_REQUESTED) {
        this.props.fetchValidateGoogleAuthWithCode(
          this.props.socialSignin.googleCode,
          prevProps.i18n.locale,
          getCouponFromUrl()
        );
      }
    }

    if (this.props.login.status !== prevProps.login.status) {
      if (this.props.login.status === UNCONFIRMED_AUTH) {
        const { signup, relativePath } = this.props;
        const email = signup && signup.form && signup.form.email;

        if (email) {
          this.reSendConfirmEmail(email);
          const workspaceLink = new URLSearchParams(window.location.search).get("invite");
          this.props.router.navigate(
            `${relativePath}/signup/confirm${workspaceLink ? `?invite=${workspaceLink}` : ""}`
          );
        }
      }

      if (this.props.login.status === AUTH_COMPLETED) {
        cursorLocalWait(true);
        this.props.fetchMe(this.props.login.authToken).finally(() => cursorLocalWait(false));
      }
    }

    if (this.props.user.status !== prevProps.user.status) {
      if (this.props.user.status === ME_FETCHED) {
        const method = prevProps.login.authMethod || prevProps.socialSignin.authMethod;
        const authToken = prevProps.login.authToken || prevProps.socialSignin.authToken;
        const refreshToken = prevProps.login.refreshToken || prevProps.socialSignin.refreshToken;
        const { email, name, locale, id } = this.props.user;
        const isProd = process.env.NODE_ENV === "production";
        const isNewUserAuthBySocialBtn = this.props.socialSignin.created;
        if (isNewUserAuthBySocialBtn) {
          // temp fix
          let time;
          time = moment(this.props.user.createdTime.seconds * 1000);
          let date = time ? time.format().toString() : "";

          // temp fix
          woopra
            .identify({
              email: email,
              name: name,
              locale: locale,
              id: id,
              signed_up: date,
            })
            .push();
        } else {
          woopra
            .identify({
              email: this.props.user.email,
              name: this.props.user.name,
            })
            .push();
          woopra.track("signed in", {
            method: method,
            env: process.env.SERVER_NAME,
          });
        }

        setCookie("auth_token", authToken, 3650);
        setCookie("refresh_token", refreshToken, 3650);

        window?.dataLayer?.push({
          event: isNewUserAuthBySocialBtn ? "nitro_signup" : "nitro_signin",
          nitro_user_id: this.props.user.id,
          is_prod: isProd,
        });

        if (prevProps.redirect) {
          const workspaceLink = new URLSearchParams(window.location.search).get("invite");
          if (workspaceLink) {
            return (window.location = `${prevProps.redirect}?invite=${workspaceLink}`);
          }
          return (window.location = prevProps.redirect);
        } else window.location = "/";
      }
    }
  }

  componentDidMount() {
    this.props.initAuth();
    !this.props.isNotSetTitle && this.props.setTitle(I18n._translate("LOGIN_PAGE.PAGE_TITLE"));
    woopra.track("hit signin page", {
      locale: this.props.i18n.locale,
      env: process.env.SERVER_NAME,
    });
  }

  handleFetchAuth = form => {
    const { fetchAuth, failAuth } = this.props;
    const { email } = form;
    setCookie("auth_token", "");
    setCookie("refresh_token", "");
    localStorageService.setWorkspaceId("");
    this.setState({ closeLoginError: false, closeSocialSigninError: false }, () => {
      fetchAuth(form, getCouponFromUrl())
        .then(responce => {
          setCookie("refresh_token", responce.refreshToken, 3650);
          cursorLocalWait(true);
        })
        .catch(error => {
          console.log(error, "errorerrorerrorerror");
          if (error && error.serviceError && error.serviceError.userNotActiveError) {
            failAuth({
              code: 13,
              message: I18n.t("LOGIN_PAGE.ERRORS.USER_NOT_ACTIVE", { email }),
            });

            return;
          }

          failAuth({
            code: 13,
            message: I18n.t("LOGIN_PAGE.ERRORS.WRONG_CREDENTIALS"),
          });
        });
    });
  };

  reSendConfirmEmail = email => {
    const { resendConfirmEmail } = this.props;
    resendConfirmEmail(email);
  };

  closeLoginErrorMessage = () => {
    this.setState({ closeLoginError: true });
  };
  closeSocialSigninErrorMessage = () => {
    this.setState({ closeSocialSigninError: true });
  };

  handlerGoogleAuth = () => {
    setCookie("auth_code", "");
    setCookie("auth_token", "");
    setCookie("refresh_token", "");

    win = window.open(process.env.GOOGLE_AUTH_LINK, "_blank", "height=600,width=400");
    const requestAuth = this.props.fetchValidateGoogleAuthWithCode;
    const locale = this.props.i18n.locale;

    let id = setInterval(() => {
      const authCode = getCookie("auth_code");
      if (win.closed || authCode) {
        clearInterval(id);
        win.close();

        if (authCode) {
          setCookie("auth_code", "");
          requestAuth(authCode, locale, getCouponFromUrl());
        }
      }
    }, 500);
  };

  render() {
    const formLoginErrorDisplay = this.props.login.status === AUTH_ERROR && !this.state.closeLoginError;
    const formSocialSigninErrorDisplay =
      this.props.socialSignin.status === EMAIL_FALLBACK && !this.state.closeSocialSigninError;
    const { relativePath } = this.props;
    const workspaceLink = getWorkspaceLinkFromUrl();
    const coupon = getCouponFromUrl();
    const discount = getDiscountFromUrl();
    const signUpUrl = coupon
      ? `/signup?coupon=${coupon}${workspaceLink ? "&invite=" + workspaceLink : ""}`
      : discount
        ? `/signup?discount=${discount}${workspaceLink ? "&invite=" + workspaceLink : ""}`
        : workspaceLink
          ? `/signup?invite=${workspaceLink}`
          : "/signup";

    return (
      <div style={{ fontSize: 14, fontWeight: 300 }}>
        <FormError
          onClose={this.closeLoginErrorMessage}
          message={this.props.login.error}
          display={formLoginErrorDisplay}
        />
        <FormError
          onClose={this.closeSocialSigninErrorMessage}
          message={this.props.socialSignin.error}
          display={formSocialSigninErrorDisplay}
        />
        <LoginForm
          relativePath={relativePath}
          disablingTrigger={this.props.app.disablingTrigger}
          disabled={this.props.app.status === APP_DISABLED}
          validator={this.props.validateForm}
          handler={e => this.handleFetchAuth(e)}
          facebookHandler={this.props.fetchRequestFacebookAuth}
          googleHandler={this.handlerGoogleAuth}
          socialSignin={this.props.socialSignin}
          styleForm={this.props.styleForm}
          invitedWorkspaceEmail={this.props.outletContext?.invitedWorkspaceEmail}
        />
        <p className="form-alt" style={this.props.styleFormAlt}>
          <HtmlTranslate value="FORGOT_PASSWORD_PAGE.SIGNUP_TEXT" />{" "}
          <Link style={{ textDecoration: "underline", borderBottom: "none" }} to={`${relativePath}${signUpUrl}`}>
            <HtmlTranslate value="FORGOT_PASSWORD_PAGE.SIGNUP_LINK" />
          </Link>
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    socialSignin: state.socialSignin,
    app: state.app,
    i18n: state.i18n,
    user: state.user,
    signup: state.signup,
    redirect: getParameterByName("continue"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRequestFacebookAuth: () => {
      dispatch(fetchRequestFacebookAuth());
    },
    fetchValidateFacebookAuth: (facebookToken, locale, coupon) => {
      dispatch(facebookAction({ serviceToken: facebookToken, locale, coupon }));
    },
    fetchValidateGoogleAuthWithCode: (googleCode, locale, coupon) => {
      dispatch(googleAction({ serviceToken: googleCode, locale, coupon }));
    },
    validateForm: () => {
      dispatch(validateForm());
    },
    fetchAuth: (form, coupon) => {
      return dispatch(emailAction({ email: form.email, password: form.password, coupon }));
    },
    failAuth: error => {
      dispatch(failAuth(error));
    },
    fetchMe: token => {
      return dispatch(fetchMeAction(token));
    },
    initAuth: () => {
      dispatch(initAuth());
    },
    resendConfirmEmail: email => {
      return dispatch(resendConfirmEmailAction({ email }));
    },
    setTitle: title => {
      dispatch(setTitle(title));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withOutletContext(Login)));
